import './index.scss'
import { HashLink as Link } from 'react-router-hash-link'
import logo from '../../img/logo.svg'

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="footer__content">
          <Link to="/">
            <img
              className="footer__logo"
              alt="Logo"
              src={logo}
            />
          </Link>

          <div className="footer__right">
            <p>
              © Всі права захищені «Dubidoc» {new Date().getFullYear()}
            </p>
            <Link to="/public-offer/">
              Правила та Політика сайту
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer