import { useState } from 'react'
import './index.scss'
import arrow from './img/arrow-down.svg'
import download from './img/download.svg'
import key from './img/key.svg'
import send from './img/send.svg'

const About = ({ onFormSent, formSent }) => {
  const [clicked, setClicked] = useState(false)

  const handleBtnClick = (e) => {
    const dialog = e.target.closest('.about__phone-wrapper').querySelector('.about__dialog')
    dialog.scrollTo({top: dialog.scrollHeight, behavior: 'smooth' })
    setClicked(true)
  }

  return (
    <div className="about" id="about">
      <div className="container">
        <div className="about__content">
          <div className="about__left">
            <h1 className="about__title">
              <span>Безкоштовний</span> сервіс для підпису та обміну документами
            </h1>
            <ul className="about__list">
              <li>
                <span className="about__list-num">
                  <img src={download} alt="Download" />
                </span>
                <span>Завантажте документи: акт, договір, рахунок тощо (в pdf та інших форматах)</span>
              </li>
              <li>
                <span className="about__list-num">
                  <img src={key} alt="Key" />
                </span>
                <span>Підпишіть за допомогою Дія, КЕП(ЕЦП) або SmartID</span>
              </li>
              <li>
                <span className="about__list-num">
                  <img src={send} alt="Send" />
                </span>
                <span>Поділіться через месенджер або email (як в Google Docs)</span>
              </li>
            </ul>
            <a href="https://my.dubidoc.com.ua/auth" target="_blank" className="button-main" id="try-firstscreen">
              Спробувати
            </a>
          </div>
          <div className="about__right">
            <div className="about__phone-wrapper">
              <img
                className="about__phone"
                alt="Iphone"
                src={require('./img/iphone.png')}
                srcSet={`${require('./img/iphone@2x.png')} 2x, ${require('./img/iphone@3x.png')} 3x`}
              />
              <div className={`about__dialog ${clicked ? 'about__dialog--clicked' : ''}`}>
                <img
                  alt="Iphone"
                  src={require('./img/dialog.png')}
                  srcSet={`${require('./img/dialog@2x.png')} 2x, ${require('./img/dialog@3x.png')} 3x`}
                />
              </div>
              {!clicked && (
                <div
                  className="about__phone-btn"
                  onClick={handleBtnClick}
                >
                  <img src={arrow} alt="Arrow" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About