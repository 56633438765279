import { useState, useEffect } from 'react'
import Header from '../components/header'
import About from '../components/about'
import Video from '../components/video'
import Reviews from '../components/reviews'
// import Contact from '../components/contact'
import Footer from '../components/footer'
import Advantages from '../components/advantages'
// import App from '../components/app'
import Web from '../components/web'
import Mobile from '../components/mobile'
import Description from '../components/description'
import Faq from '../components/faq'

const Main = () => {
  const [formSent, setFormSent] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="App">
      <Header />
      <main>
        <About
          onFormSent={(e) => setFormSent(e)}
          formSent={formSent}
        />
        <Video />
        <Description />
        <Web />
        <Mobile />
        {/*<App />*/}
        <Advantages />
        <Reviews />
        <Faq />
        {/*<Contact*/}
        {/*  onFormSent={(e) => setFormSent(e)}*/}
        {/*  formSent={formSent}*/}
        {/*/>*/}
      </main>
      <Footer />
    </div>
  )
}

export default Main
